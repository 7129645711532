import React, {FunctionComponent, useEffect, useState} from 'react'
import AutoSizer from "react-virtualized-auto-sizer";

import {
  ContainerCard,
  ContainerCardProps,
} from "../surfaces/visualization/ContainerCard";

interface IFrameComponentProps extends ContainerCardProps {
    title: string
    src: string
    vmname?: string | null
    minWidth?: string
    minHeight?: string
}

// if the IFrameComponent requires a vmName, pass one using the vmName prop, pass the url as a normal string with the
// vmName in the string as '{{vmname}}' , this will get replaced if the vmName prop is not null

const IFrameComponent: FunctionComponent<IFrameComponentProps> = (props) => {

    const [src, setSrc] = useState(props.src)
    useEffect(() => {!!props.vmname && setSrc(props.src.replace('{{vmname}}', props.vmname))}, [props.src, props.vmname])


      return (
          <ContainerCard innerProps={{ p: 0 }} {...props}>
            <AutoSizer>
              {({ height, width }: any) => {
                if (!height || !width) return null;
                return (
                    <iframe
                        title={props.title}
                        src={src}
                        width={width}
                        height={height}
                        frameBorder="0"
                    />
                );
              }}
            </AutoSizer>
          </ContainerCard>
      )
}

export default IFrameComponent
